<template>
  <div class="navbar-brand is-justify-content-space-between">
    <router-link
      to="/"
      class="navbar-logo"
      :class="{ 'dropdown is-hoverable': !isIntegration }"
      @click.native="$mxp.track('click_logo_dashboard_page_load')"
    >
      <div class="dropdown-trigger menu-item">
        <img
          :src="require(`core/img/logos/${[logo]}-logo.png`)"
          alt="Percayso Trade"
          aria-haspopup="true"
          aria-controls="brand-menu"
          class="animated flipInX logo-width"
        >
        <span class="icon is-medium is-hidden-mobile">
          <i
            class="fas fa-caret-down"
            aria-hidden="true"
          />
        </span>
      </div>
      <div
        class="dropdown-menu has-arrow is-left"
        id="brand-menu"
        role="menu"
      >
        <div class="dropdown-content">
          <AppHeaderBrandNav />
        </div>
      </div>
    </router-link>
    <div
      class="navbar-item is-hidden-desktop has-text-weight-semibold"
      @click="toggle"
    >
      <i
        v-if="mobNav"
        class="fal fa-times is-size-5"
      />
      <span
        class="menu-item" 
        v-else
      >
        Menu</span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
export default {
  name: 'AppHeaderBrand',
  components: {
    AppHeaderBrandNav: () => import('./AppHeaderBrandNav')
  },
  computed: {
    ...mapGetters({ isIntegration: 'auth/isIntegration' }),
    ...mapState('overlay', ['mobNav']),
    logo() {
      return process.env.VUE_APP_TYPE
    },
  },
  methods: {
    ...mapMutations('overlay', ['toggle'])
  }
}
</script>

<style lang="sass" scoped>
.dropdown-trigger
  display: flex
  align-items: center
.navbar-logo
  padding: 0.5rem 0.75rem
.logo-width
  width: $logo-width
  height: auto
</style>
